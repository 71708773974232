import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBar.css';

const Navbar = ({ isAuthenticated, onLogout }) => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">RadCoPilot</div>
      <ul className="navbar-nav">
        {!isAuthenticated ? (
          <>
            <li className="nav-item">
              <Link to="/register" className="nav-link">Register</Link>
            </li>
            <li className="nav-item">
              <Link to="/login" className="nav-link">Login</Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav-item">
              <Link to="/RadCoPilot" className="nav-link">RadCoPilot</Link>
            </li>
            <li className="nav-item">
              <button onClick={onLogout} className="nav-link logout-button">Logout</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;