import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';
import '../styles/ReportHistorySidebar.css';

const ReportHistorySidebar = ({ onSelectReport }) => {
console.log('ReportHistorySidebar component rendered');
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get('/api/report-history/');
        console.log('Raw API response:', response);
        console.log('Fetched reports:', response.data);
        // Ensure we're setting the correct data structure
        setReports(Array.isArray(response.data) ? response.data : []);
        setError(null);
      } catch (error) {
        console.error('Error fetching report history:', error);
        setError('Failed to fetch reports. Please try again.');
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  console.log('Current reports state:', reports);

  return (
    <div className="report-history-sidebar">
      <h3>Report History</h3>
      {isLoading ? (
        <p>Loading reports...</p>
      ) : error ? (
        <p>{error}</p>
      ) : reports.length === 0 ? (
        <p>No reports found.</p>
      ) : (
        <ul>
          {reports.map((report) => (
            <li key={report.id} onClick={() => onSelectReport(report.content)}>
              <span className="report-date">
                {report.created_at ? new Date(report.created_at).toLocaleString() : 'Date not available'}
              </span>
              <p className="report-preview">{report.content.substring(0, 50)}...</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ReportHistorySidebar;
