import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ReportHistorySidebar from './ReportHistorySidebar';
import '../styles/RadCoPilot.css';
import { getAutocompletion, getChatResponse } from '../api/openai';
import ReactMarkdown from 'react-markdown';

const RadCoPilot = () => {
  const [text, setText] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showHistorySidebar, setShowHistorySidebar] = useState(false);
  const textareaRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const latestRequestIdRef = useRef(0);
  const [selectedText, setSelectedText] = useState('');
  const [selectionPosition, setSelectionPosition] = useState({ x: 0, y: 0 });
  const [showChat, setShowChat] = useState(false);
  const [chatResponse, setChatResponse] = useState('');

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };
  
  const debouncedGetAutocompletion = useCallback((newText) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    latestRequestIdRef.current++;
    const currentRequestId = latestRequestIdRef.current;

    debounceTimeoutRef.current = setTimeout(async () => {
      if (newText.trim() !== '') {
        try {
          // Get the current cursor position directly from the textarea
          const currentPosition = textareaRef.current.selectionStart;
          const beforeCursor = newText.slice(0, currentPosition);
          const afterCursor = newText.slice(currentPosition);
          
          const textWithCursor = `${beforeCursor}|${afterCursor}`;
          const autocompletion = await getAutocompletion(textWithCursor);
          
          if (currentRequestId === latestRequestIdRef.current) {
            setSuggestion(autocompletion);
          }
        } catch (error) {
          console.error('Error getting autocompletion:', error);
        }
      }
    }, 500);
  }, []);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    
    // Get the actual cursor position from the textarea
    const newPosition = e.target.selectionStart;
    setCursorPosition(newPosition);
    
    // Force immediate cursor position update
    textareaRef.current.selectionStart = newPosition;
    textareaRef.current.selectionEnd = newPosition;
    
    setSuggestion('');
    debouncedGetAutocompletion(newText);
  };

  const handleKeyDown = (e) => {
    if (e.shiftKey && e.key === 'Tab' && suggestion) {
      // Handle Shift+Tab for single word
      e.preventDefault();
      const words = suggestion.split(' ');
      const firstWord = words[0];
      
      const beforeCursor = text.slice(0, cursorPosition);
      const afterCursor = text.slice(cursorPosition);
      
      const newText = beforeCursor + firstWord + ' ' + afterCursor;
      setText(newText);
      
      // Update cursor position after the inserted word and space
      const newPosition = cursorPosition + firstWord.length + 1;
      setCursorPosition(newPosition);
      
      // Update the remaining suggestion
      const remainingSuggestion = words.slice(1).join(' ');
      setSuggestion(remainingSuggestion);

      // If no remaining suggestion, request a new one
      if (!remainingSuggestion) {
        debouncedGetAutocompletion(newText);
      }
      
      // Set cursor position after state update
      setTimeout(() => {
        textareaRef.current.selectionStart = newPosition;
        textareaRef.current.selectionEnd = newPosition;
      }, 0);
    } else if (e.key === 'Tab' && suggestion) {
      // Handle Tab for full suggestion
      e.preventDefault();
      const beforeCursor = text.slice(0, cursorPosition);
      const afterCursor = text.slice(cursorPosition);
      
      const newText = beforeCursor + suggestion + ' ' + afterCursor;
      setText(newText);
      
      // Update cursor position after the full suggestion and space
      const newPosition = cursorPosition + suggestion.length + 1;
      setCursorPosition(newPosition);
      setSuggestion('');

      // Request new suggestion after accepting the full one
      debouncedGetAutocompletion(newText);
      
      // Set cursor position after state update
      setTimeout(() => {
        textareaRef.current.selectionStart = newPosition;
        textareaRef.current.selectionEnd = newPosition;
      }, 0);
    } else if (e.key === 'Backspace' && suggestion) {
      e.preventDefault();
      setSuggestion('');
    } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) {
      // Clear suggestion when moving cursor with arrow keys
      setSuggestion('');
    }
  };

  // Modify the handleClick function
  const handleClick = (e) => {
    const newPosition = e.target.selectionStart;
    
    // Clear suggestion if cursor moves
    if (newPosition !== cursorPosition) {
      setSuggestion('');
    }
    setCursorPosition(newPosition);
  };

  const handleSelect = (e) => {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    
    // Clear suggestion if text is selected
    if (selectionStart !== selectionEnd) {
      setSuggestion('');
    }
  };

  useEffect(() => {
    // Add event listener for text selection
    document.addEventListener('mouseup', handleTextSelection);
    
    // Cleanup
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      setSelectedText('');
      setSelectionPosition({ x: 0, y: 0 });
    };
  }, []);

  // Chat interface text selection
  const handleTextSelection = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value.substring(start, end).trim();
      
      if (text && start !== end) {
        // Get textarea's position
        const textareaRect = textarea.getBoundingClientRect();
        
        // Calculate position of selection within textarea
        const textBeforeSelection = textarea.value.substring(0, end);
        const lines = textBeforeSelection.split('\n');
        const lastLine = lines[lines.length - 1];
        const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
        
        // Calculate character width (assuming monospace font)
        const charWidth = 9.5; // Approximate width of a character in pixels
        
        // Calculate x position based on the end of selection
        const x = textareaRect.left + ((lastLine.length) * charWidth);
        
        // Calculate y position
        const y = textareaRect.top + ((lines.length - 1) * lineHeight);
        
        setSelectionPosition({
          x: x,
          y: y
        });
        setSelectedText(text);

      } else {
        setSelectedText('');
      }
    }
  };

  const handleSaveAndCopy = async () => {
    try {
      await axios.post('/api/report-history/', 
        { content: text },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      );
      navigator.clipboard.writeText(text).then(() => {
        setShowPopup(true);
        setText('');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    } catch (error) {
      console.error('Error saving report:', error);
    }
  };

  const handleSelectReport = (reportContent) => {
    setText(reportContent);
  };

  const toggleHistorySidebar = () => {
    console.log('Toggling sidebar, current state:', showHistorySidebar);
    setShowHistorySidebar(!showHistorySidebar);
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const renderTextWithSuggestion = () => {
    if (!suggestion) return text;
    
    const beforeCursor = text.slice(0, cursorPosition);
    const afterCursor = text.slice(cursorPosition);
    
    return (
      <>
        {beforeCursor}
        <span className="suggestion-text">{suggestion}</span>
        {afterCursor}
      </>
    );
  };

  const handleChatClick = async () => {
    try {
      // Debug logging
      const token = localStorage.getItem('access_token');
      console.log('Access token present:', !!token);
      console.log('Selected text:', selectedText);
      
      const response = await getChatResponse(selectedText);
      setChatResponse(response);
      setShowChat(true);
    } catch (error) {
      console.error('Error getting chat response:', error);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      setChatResponse('Sorry, I encountered an error processing your request.');
    }
  };

  return (
    <div className={`radcopilot-container ${showHistorySidebar ? 'sidebar-open' : ''}`}>
      <div className="radcopilot-main">
        <h2 className="radcopilot-title">Write your report:</h2>
        <div className="button-container">
          <button className="help-button" onClick={toggleHelp}>?</button>
          <button className="save-copy-button" onClick={handleSaveAndCopy}>Save and Copy Report</button>
          <button className="history-button" onClick={toggleHistorySidebar}>
            {showHistorySidebar ? 'Hide History' : 'Show History'}
          </button>
        </div>
        {showHelp && (
          <div className="help-modal">
            <h3>Keyboard Shortcuts</h3>
            <ul>
              <li><strong>Shift + Tab:</strong> Insert the first word of the suggestion</li>
              <li><strong>Tab:</strong> Insert the entire suggestion</li>
              <li><strong>Backspace:</strong> Clear the suggestion</li>
            </ul>
            <button onClick={toggleHelp}>Close</button>
          </div>
        )}
        <div className="radcopilot-form">
          <div className="textarea-wrapper">
            <div className="text-content">
              {renderTextWithSuggestion()}
            </div>
            <textarea
              ref={textareaRef}
              value={text}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              onSelect={handleSelect}
              onMouseUp={handleTextSelection}
              className="rad-textarea"
            />
          </div>
        </div>
        {showPopup && <div className="copy-popup">Report saved successfully and copied to clipboard!</div>}
      </div>
      {showHistorySidebar && (
        <ReportHistorySidebar onSelectReport={handleSelectReport} />
      )}
      {selectedText && !showChat && (
        <button
          className="chat-trigger-button"
          style={{
            position: 'fixed',
            left: `${selectionPosition.x}px`,
            top: `${selectionPosition.y}px`,
          }}
          onClick={handleChatClick}
        >
          💬
        </button>
      )}
      {showChat && (
        <div className="chat-popup">
          <div className="chat-popup-content">
            <button className="close-button" onClick={() => setShowChat(false)}>×</button>
            <ReactMarkdown>{chatResponse}</ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default RadCoPilot;
