import axios from 'axios';

const API_URL = 'https://herokuradcopilot-6fbe85a5512a.herokuapp.com/auth/';

export const register = (username, email, password, key) => {
  return axios.post(API_URL + 'register/', {
    username,
    email,
    password,
    key
  });
};

export const login = (username, password) => {
  return axios.post(API_URL + 'token/', {
    username,
    password
  });
};

export const refreshToken = (refresh) => {
  return axios.post(API_URL + 'token/refresh/', {
    refresh
  });
};
